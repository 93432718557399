.versions-diff{
  .versions-selector{
    border-bottom: $border;
    padding-bottom: $global-margin * .5;
    color: $muted;
    font-size: rem-calc(19);
    display: flex;
    align-items: center;

    //Override foundation
    .tabs{
      width: 100%;

      @include flexgap(.5rem);
    }
  }

  //Override foundation
  .tabs,
  .tabs-content{
    background: transparent;
  }

  //Override foundation
  .tabs-title{
    > a{
      padding: 0;

      &:hover{
        background: transparent;
      }
    }

    > a[aria-selected='true']{
      background: transparent;
    }

    &:not(.is-active) .button{
      opacity: .4;
    }
  }

  .diff-preview{
    margin: 1em 0;

    .heading3{
      font-size: 1.5em;
      margin-bottom: 1em;
    }
  }
}

.diff-direction-label{
  display: block;
  font-size: 85%;
  line-height: 1;
  margin-bottom: .25rem;
  color: $muted;
}

.diff{
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background: transparent;
  }

  del,
  ins,
  span{
    white-space: pre-wrap;
  }

  del,
  ins{
    display: block;
    text-decoration: none;
  }

  .del strong,
  .del strong *{
    font-weight: normal;
    background: scale-color($color-removal, $lightness: -10%);
    display: inline;
  }

  .ins strong,
  .ins strong *{
    font-weight: normal;
    background: scale-color($color-addition, $lightness: -10%);
    display: inline;
  }

  li{
    position: relative;
    padding: .1rem 1rem .1rem 1.5rem;
    margin: 0;

    &.ins,
    &.del{
      .symbol{
        position: absolute;
        left: .5rem;
        top: .1rem;
        width: 1rem;
      }
    }

    &.ins{
      background: scale-color($color-addition, $lightness: 30%, $saturation: 30%);
      color: scale-color($color-addition, $lightness: -75%, $saturation: -75%);
    }

    &.del{
      background: scale-color($color-removal, $lightness: 30%, $saturation: 30%);
      color: scale-color($color-removal, $lightness: -75%, $saturation: -75%);
    }

    .diff-comment{
      display: none;
    }

    .diff-block-info{
      background: none repeat scroll 0 0 gray;
    }
  }
}