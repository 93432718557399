// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override CSS variables or Foundation settings use _decidim-settings.scss
//
// By default this is empty.

.logo-wrapper {
	> svg {
		height: 45px;
		width: auto;
		fill: #3d9f3b;
	}
}
.wrapper {
	&.watch_race {
		padding:0;
	}
	.watch_race-iframe {
		margin:0 auto;
	 iframe {
	 	display:block;
	 	margin:auto
	 }
	}
}

#watchRaceIframe {
	width: 1px;
  min-width: 100%;
  min-height: 2500px;
}

.main-nav {
	.main-nav__link {
		a[href="/watch_race"] {
			padding-left: 1em;
			img {
				margin: 0 1em 0 0.6em;
				height: 1em;
			}
		}
	}
}

$small: 576px;

@media screen and (max-width: $small) {
		.conference-speaker {
			div.speaker-bio.js-bio {
				position: -webkit-sticky;
				position: fixed;
				top: 60px !important;
				overflow-y: scroll !important;
				max-height: 95vh;
				height: fit-content;
				overflow: visible;
			}
		}
}
